import { CountryCodeList } from 'types/countries';
import { EXP_MENU_LINK_LABEL_MY_SEARCHES, EXP_MENU_LINK_LABEL_SIGN_IN } from './exprealtyCA';

import type { CountryCode } from 'types/countries';
import type { SiteLanguage } from 'constants/locale';

interface ThemeColors {
  primaryVeryLight: string;
  primaryLight: string;
  primary: string;
  primaryDark: string;
  primaryVeryDark: string;
  primaryBackground: string;
  primaryFontColor: string;
  primaryForSale: string;
  primarySold: string;
  primaryFilters: string;
  primaryLinkColor: string;
  primaryInvertedBackground: string;
  primaryBorderColor: string;
  primaryChart: string;
  secondaryBackground: string;
  secondaryBorderColor: string;
  secondaryLinkColor: string;
  tertiaryBackground: string;
  tertiaryBorderColor: string;
  tertiaryLinkColor: string;
  quaternaryLinkColor: string;
  transparentBackground: string;
  mapPinForSale: string;
}

interface ThemeFonts {
  heading: string;
  text: string;
  headingWeight: string;
  headingBoldWeight: string;
}

export const SEARCH_OPTION_BUY = 'Buy';
export const SEARCH_OPTION_RENT = 'Rent';
export const SEARCH_OPTION_SOLD = 'Sold';
export const SEARCH_OPTION_AGENTS = 'Agents';
export const SEARCH_OPTION_HOME_APPRAISAL = 'HomeAppraisal';
export type SearchOptions = typeof SEARCH_OPTION_BUY | typeof SEARCH_OPTION_RENT | typeof SEARCH_OPTION_SOLD | typeof SEARCH_OPTION_AGENTS | typeof SEARCH_OPTION_HOME_APPRAISAL;

type FooterColItem = {
  label: string;
  link?: string;
  target?: string;
  isAnchor?: boolean;
};

export interface ThemeConfig {
  name: string;
  title: string;
  metaDescription: string;
  largeDesktopHeaderImage: string | undefined;
  desktopHeaderImage: string | undefined;
  mobileHeaderImage: string | undefined;
  smallMobileImage: string | undefined;
  ogSiteName: string;
  ogImage: string;
  schemaUrl: string;
  schemaLogo: string;
  schemaLocation: {
    addressCountry: string;
    addressLocality: string;
    addressRegion: string;
    postalCode: string;
    streetAddress: string;
  };
  schemaFoundingDate: string;
  schemaFounders: {
      '@type': string;
      name: string;
  }[];
  faviconPath: string;
  colors: ThemeColors;
  fonts: ThemeFonts;
  decorations: {
    brushstrokes: boolean;
    boxShadow: string;
    boxShadowLight: string;
    areaPageLineWidth: string;
  };
  buttons: {
    borderRadius: string;
    borderRadiusSmall: string;
    borderRadiusMedium: string;
    borderRadiusLarge: string;
    borderRadiusModal: string;
  };
  icons: {
    borderRadius: string;
  };
  organization: {
    phoneNumber: (isOnPreConPage?: boolean) => string | null;
    email: (isOnPreConPage?: boolean) => string | null;
    address: (siteLocation?: CountryCodeList) => string | null;
    twitterUrl: string;
    facebookUrl: string;
    instagramUrl: string;
    linkedinUrl: string;
    youtubeUrl?: string;
    joinSectionUrl?: string;
  };
  menuLinks: {
    label: typeof EXP_MENU_LINK_LABEL_SIGN_IN | typeof EXP_MENU_LINK_LABEL_MY_SEARCHES | string;
    link?: string;
    isEmber?: boolean;
    newTab?: boolean;
  }[];
  footerContent?: {
    column1?: FooterColItem[];
    column2?: FooterColItem[];
    column3?: FooterColItem[];
    bottom?: FooterColItem[][];
  };
  searchOptions: {
    type: SearchOptions;
    placeholder: (siteLocation?: CountryCode, isOnMobile?: boolean) => string;
  }[];
  contentBlockBuy: {
    image: string;
    title: string;
    copy: string;
    link: string;
  };
  contentBlockSell: {
    image: string;
    title: string;
    copy: string;
    link: string;
  };
  privacyPolicy: (language?: SiteLanguage) => string;
  termsOfUse: (language?: SiteLanguage) => string;
}
