import SearchPrediction, {
  createSearchPredictionModel,
} from 'data/search-predictions';
import googleQuery from './api/placePredictions';
import { AutocompletePredictionPredictionCategory, QueryResultType, QuerySettingsLimitType, QuerySettingsType, SEARCH_PREDICTION_SOURCE_GOOGLE } from './types';
import { getPredictionCategory } from './predictionClassifier';

export const US_AND_CANADA_COUNTRY_FILTER: readonly string[] = ['ca', 'us'];
export const CANADA_COUNTRY_FILTER: readonly string[] = ['ca'];

export const DefaultQuerySettings: QuerySettingsType = {
  limits: {
    city: 2, neighborhood: 2, street: 1,
  },
  autocompleteProvider: googleQuery,
};

export function createQueryFunction(useUsListings: boolean): (input: string, settings?: QuerySettingsType) => Promise<SearchPrediction[]> {
  return (input: string, settings: QuerySettingsType = DefaultQuerySettings) => query(input, settings, useUsListings ? US_AND_CANADA_COUNTRY_FILTER : CANADA_COUNTRY_FILTER);
}

export async function query(
  input: string,
  settings: QuerySettingsType = DefaultQuerySettings,
  country: readonly string[]
): Promise<SearchPrediction[]> {
  const { limits, autocompleteProvider: query = googleQuery } = settings;
  const googlePredictions = await query(input, country);

  const searchPredictions = formatSearchResults(googlePredictions, limits).map(
    createSearchPredictionModel
  );
  return searchPredictions;
}

function formatSearchResults(
  predictions: QueryResultType,
  limits: QuerySettingsLimitType
) {

  const cities = predictions.city.slice(0, limits.city) || [];
  const citiesLeftOverSlots = limits.city - cities.length;
  const neighborhoods = predictions.neighborhood.slice(0, limits.neighborhood + citiesLeftOverSlots) || [];
  const neighborhoodsLeftOverSlots = limits.neighborhood - neighborhoods.length;
  const streets = predictions.street.slice(0, limits.street + neighborhoodsLeftOverSlots) || [];

  return [
    ...autocompletePredictionToSearchResults(cities),
    ...autocompletePredictionToSearchResults(neighborhoods),
    ...autocompletePredictionToSearchResults(streets)] as SearchPrediction[];
}

export function autocompletePredictionToSearchResults(autocompletePrediction: google.maps.places.AutocompletePrediction[]) {
  return autocompletePrediction.map(prediction => {
    let label;
    const predictionCategory = getPredictionCategory(prediction?.types);
    switch (predictionCategory) {
    case AutocompletePredictionPredictionCategory.CITY:
      label = 'city';
      break;
    case AutocompletePredictionPredictionCategory.NEIGHBORHOOD:
      label = 'neighbourhood';
      break;
    case AutocompletePredictionPredictionCategory.STREET:
      label = 'street';
      break;
    default:
      label = 'city';
    }

    return {
      id: prediction.place_id,
      description: prediction.description.replace(', Canada', '').replace(', USA', ''),
      group: 'locations',
      matchedSubstrings: prediction.matched_substrings,
      data: {
        position: {
          type: 'Point',
          coordinates: [0, 0],
        },
      },
      label,
      source: SEARCH_PREDICTION_SOURCE_GOOGLE,
    };
  });
}
